import React from 'react'

import { Helmet } from 'react-helmet'
import ImageSlider from '../components/ImageSlider'
import ImageGrid from '../components/ImageGrid'

import { Link } from 'react-router-dom'

import './english.css'
const slides = [
  { url: 'https://yamagatadental.net/public/grandcentralinterior.jpg' },
  { url: 'https://yamagatadental.net/public/helmseyinterior.jpg' },
  { url: 'https://yamagatadental.net/public/20240119_162058ratio.jpg' },
  { url: 'https://yamagatadental.net/public/sign.jpg' },
  { url: 'https://yamagatadental.net/public/Origami.jpg' }
];
const images = [
  { url: 'https://yamagatadental.net/public/staff1ratio.jpg' },
  { url: 'https://yamagatadental.net/public/patient1.jpg' },
  { url: 'https://yamagatadental.net/public/patient2.jpg' },
  { url: 'https://yamagatadental.net/public/patient3.jpg' },
  { url: 'https://yamagatadental.net/public/patient4.jpg' },
  { url: 'https://yamagatadental.net/public/patient5.jpg' },
  { url: 'https://yamagatadental.net/public/patient6.jpg' },
  { url: 'https://yamagatadental.net/public/patient7.jpg' },
  { url: 'https://yamagatadental.net/public/staff3.jpg' },
  { url: 'https://yamagatadental.net/public/staff4.jpg' },

];
const English = (props) => {
  return (
    <div className="english-container">
      <Helmet>
        <title>yamagatadental.net/public/</title>
        <meta property="og:title" content="yamagatadental.net/public/" />
      </Helmet>
      <header data-thq="thq-navbar" className="english-navbar-interactive">
        <img
          alt="logo"
          src="/public/yamagatalogo.png"
          className="english-image"
        />
        <span className="english-text">
          <span>Yamagata Dental P.C.</span>
          <br></br>
          <span>山縣歯科医院</span>
        </span>
        <div data-thq="thq-navbar-nav" className="english-desktop-menu">
          <nav className="english-links">
            <a href="#Home" className="english-link">
              Home
            </a>
            <a href="#About" className="english-link1">
                About
            </a>
            <a href="#Services" className="english-link1">
              Services
            </a>
            {/*
            <a href="#Insurance" className="english-link1">
              Insurance
            </a>
  */}
            <a href="#Location" className="english-link2">
              Location
            </a>
            <a href="#Contact" className="english-link3">
              Contact
            </a>
            <Link to= {{ pathname: '/news', state: false }} className="english-link8">
              News
            </Link>
          </nav>
          <div className="english-buttons"></div>
        </div>
        <div data-thq="thq-burger-menu" className="english-burger-menu" onClick = {ShowMobileMenu}>
          <svg viewBox="0 0 1024 1024" className="english-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="english-mobile-menu" id="mobileMenu">
          <div className="english-nav">
            <div className="english-top">
              <img
                alt="image"
                src="/public/yamagatalogo.png"
                className="english-logo"
              />
              <div data-thq="thq-close-menu" className="english-close-menu" onClick = {HideMobileMenu}>
                <svg viewBox="0 0 1024 1024" className="english-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="english-links1">
              <a href="#Home" className="english-link4" onClick = {HideMobileMenu}>
                Home
              </a>
              <a href="#About" className="english-link5" onClick = {HideMobileMenu}>
                About
              </a>
              {/*<a href="#Insurance" className="english-link5">
              Insurance
              </a>
  */}
              <a href="#Location" className="english-link6" onClick = {HideMobileMenu}>
                Location
              </a>
              <a href="#Contact" className="english-link7" onClick = {HideMobileMenu}>
                Contact
              </a>
              <Link to={{ pathname: '/news', state: false }} className="english-navlink">
                News
              </Link>
            </nav>
            <div className="english-buttons1"></div>
          </div>       
        </div>
        <Link to="/" className="english-navlink1 button">
          日本語
        </Link>
      </header>
      <div id="Home" className="english-container01">
      <ImageSlider slides = {slides}/>
      </div>
      <div id="About" className="english-container03">
        <h1 className="english-text007">General Statement</h1>
        <div className="english-container04">
          <span className="english-text008">
            <span>
              Located in Midtown Manhattan, New York across from Grand Central Station
              our clinic provides treatment and consultations in both Japanese and English.
            </span>
            <br></br>
            <br className="english-text028"></br>
            <span>         
              Serving the New York area for over 30 years Yamagata Dental Clinic is grounded
              in time-tested principles and quality service.
            </span>        
            <br className="english-text010"></br>
            <br></br>
            <span>
              We conveniently provide in-house doctors specializing
              in periodontal disease and implant treatment,
              eliminating the need to visit other specialists during the
              treatment process.
            </span>
            <br className="english-text013"></br>
            <br></br>
            <span>
              If you feel embarrassed because you have teeth that have been left
              untreated due to personal circumstances, please don't hesitate to contact us.
            </span>
            <br className="english-text016"></br>
            <br></br>
            <span>
              Many patients with serious dental problems have returned to a
              healthy lifestyle after receiving treatment at our clinic.
            </span>
            <br className="english-text019"></br>
            <br></br>
            <span>
              The pain of the anesthesia injection is also minimized, allowing
              your child to receive gentle treatment.
            </span>
            <br className="english-text022"></br>
            <br></br>
            <span>
              After your first treatment, you can look forward to maintaining
              healthy teeth for a brighter future.
            </span>
            <br className="english-text025"></br>
            <br></br>
            <span>
              Treating problem teeth and improving patients&apos; quality of
              life and work efficiency is what Yamagata Dental Clinic has been
              striving to attain for over 30 years.
            </span>      
            <br className="english-text034"></br>
            <br></br>
          </span>
        </div>
        <div className="english-container05">
          <div className="english-container06">
            <img
              alt="image"
              src="/public/dryamagata-400h.png"
              className="english-image1"
            />
            <span className="english-text036">Ren Yamagata D.D.S</span>
            <span className="english-text037">
              <span>1982 </span>
              <span>
                Graduated from Nippon Dental University Worked at Enomoto Dental
                Clinic
              </span>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>1990 </span>
              <span>
                Graduated from NYU Dental School Obtained dental license
              </span>
              <br></br>
              <br></br>
              <span>1991 Opened Yamagata Dental Clinic</span>
            </span>
          </div>
          <div className="english-container07">
            <h1 className="english-text048">
              Greetings From Director Yamagata
            </h1>
            <span className="english-text049">
              <span>
                Dr. Yamagata, who holds dental licenses in both Japan and the
                United States, has treated countless english patients for 33
                years since opening his practice in New York in 1991.
              </span>
              <br></br>
              <br></br>
              <span>
                The individuals I treat are more than just patients;
                they feel like friends and family with whom I share my life.
                It is always disheartening when patients who I have treated
                during a three to five-year tenure with a company or government agency
                return to Japan. However, some patients come back for
                treatment during business trips or reassignments, while others choose
                to stay for an extended period or permanently.
                Notably, I have been providing care to young children for an
                extended duration, and recently, there has been an increase in patients
                spanning three generations, including grandparents, parents, and
                children, who have been receiving treatment.
              </span>
              <br></br>
              <br></br>
              <span>
                Also, when a patient comes to our hospital with a complex or
                difficult case that could not be treated with general treatment
                elsewhere, and is able to return to a happy daily life after my
                treatment, my sense of accomplishment as a specialist doubles.
              </span>
              <br></br>
              <br></br>
              <span>
                I also believe that the best dental treatment is a product of
                the dentist&apos;s own heart, which stems from his deep feelings
                for each patient. Therefore, it is best to provide a treatment
                policy based on the basics and skilled techniques, based on
                extremely detailed diagnosis based on rich clinical experience
                as a treating physician, and taking into consideration the
                long-term welfare of the patient. I believe that it is a
                treatment, and I continue to provide medical treatment today.
              </span>
              <br></br>
              <br></br>
              <span>
                Yamagata Dental Clinic will continue to work even harder for the
                sake of the wonderful patients who have trusted my treatment
                over the past 30 years.
              </span>
            </span>
          </div>
        </div>
        <div className="english-container08">
        <ImageGrid images = {images}/>
        </div>
      </div>
      <div id="Services" className="english-container09">
        <h1 className="english-text063">
          <span>Dental Services</span>
          <br></br>
        </h1>
        <div className="english-container10">
          <div className="english-container11">
            <h1 className="english-text066">Preventative Care</h1>
            <span className="english-text067">
              <span>Dental Exam &amp; Check Up</span>
              <br></br>
              <br></br>
              <span>Dental Cleaning</span>
              <br></br>
              <br></br>
              <span>Periodontal Disease Treatment</span>
              <br></br>
              <br></br>
              <span>Occlusal/Night Guard</span>
              <br></br>
              <br></br>
              <span>Pediatric Dentistry</span>
              <br></br>
              <br></br>
              <span>Interceptive Orthodontics</span>
              <br></br>
              <br></br>
              <span>Sealants</span>
              <br></br>
              <br></br>
              <span>Silver Diamine Fluoride (SDF)</span>
              <br></br>
              <br></br>
            </span>
          </div>
          <div className="english-container12">
            <h1 className="english-text092">
              <span>Restorative Dentistry</span>
              <br></br>
              <br></br>
            </h1>
            <span className="english-text096">
              <span>Composite Fillings</span>
              <br className="english-text098"></br>
              <br></br>
              <span>Crowns &amp; Bridges</span>
              <br className="english-text101"></br>
              <br></br>
              <span>Extraction</span>
              <br className="english-text104"></br>
              <br></br>
              <span>Root Canal</span>
              <br className="english-text107"></br>
              <br></br>
              <span>Dental Implants</span>
              <br className="english-text110"></br>
              <br></br>
              <span>Removable Dentures</span>
              <br className="english-text113"></br>
              <br></br>
            </span>
          </div>
          <div className="english-container13">
            <h1 className="english-text115">
              <span>Cosmetic Dentistry</span>
              <br></br>
              <br></br>
            </h1>
            <span className="english-text119">
              <span>Veneers</span>
              <br className="english-text121"></br>
              <br></br>
              <span>Clear Aligners (Invisalign)</span>
              <br className="english-text124"></br>
              <br></br>
              <span>Black Triangle Closure</span>
              <br className="english-text127"></br>
              <br></br>
              <span>Teeth Whitening</span>
              <br className="english-text130"></br>
              <br></br>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      {/*
      <div id="Insurance" class="englishinsurance-container1">
      <h1 class="englishinsurance-text1">
        <span>Insurance</span>
        <br />
      </h1>
      <div class="englishinsurance-container2">
        <div class="englishinsurance-container3">
          <span class="englishinsurance-text2">
            <span>1. PPO plans compatible with various insurances</span>
            <br />
            <br />
            <span>
            2. Prestige International Inc. Affiliated clinics MUFG, MU Trust, Sumitomo Mitsui Trust, ANA, Hitachi, etc.
            </span>
            <br />
            <br />
            <span>
            3. National Health Insurance, Social Insurance (for expatriates), Creation of insurance application documents for Ministry of Foreign Affairs Mutual Aid Association Insurance
            </span>
            <br />
            <br />
            <span>4. Overseas traveler insurance (dental special contract)</span>
            <br/>
          </span>
        </div>
        <span class="englishinsurance-text3">
          <span>
          American health insurance is quite different from Japan's public health insurance system.
              Plans and insurance coverage vary depending on the insurance company you contract with.
              For this reason, people may be discouraged to see a doctor. Please don't
              hesitate to contact us so we can discuss what works for you.
          </span>
         
        </span>
      </div>
      </div>
      */}
      <div id="Location" className="english-container14">
        <div className="english-container15">
          <div className="english-container16">
            <div className="english-container17">
              <h1 className="english-text133">Location</h1>
              <span className="english-text134">
                <span></span>
                <span>Yamagata Dental, P.C.</span>
                <br></br>
                <span>230 Park Avenue, Suite 525</span>
                <br></br>
                <span>New York, NY 10169</span>
                <br></br>
                <br></br>
                <span>
                  Located across the street from the North Exit of Grand Central
                  Station
                </span>
                <br></br>
              </span>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.346294540422!2d-73.97874332345143!3d40.754407635017515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258fdf6c83d7f%3A0xa1a1f948634bb8ed!2sThe%20Helmsley%20Building%2C%20New%20York%2C%20NY%2010017!5e0!3m2!1sen!2sus!4v1705352055826!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
                className="english-iframe"
              ></iframe>
              <h1 className="english-text145">Business Hours</h1>
              <span className="english-text146">
                <span>Monday - Wednesday, Friday and Saturday</span>
                <br></br>
                <span> 10:00am to 6:00pm</span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="Contact" className="english-container18">
        <h1 className="english-text151">Contact Us</h1>
        <div className="english-container19">
          <h1 className="english-text152">
            <span>Tel 212-867-1188</span>
            <br></br>
            <span>Fax: 212-953-9110</span>
            <br></br>
          </h1>
          <h1 className="english-text157">Email: info@yamagatadental.net</h1>
          <form
            id="contactform"
            //action="process_form.php"
            //method="POST"
            className="english-form"
          >
            <label htmlFor="Name">Name</label>
            <input
              type="text"
              id="Name"
              name="name"
              required
              className="english-input input"
            />
            <label htmlFor="Email">Email</label>
            <input
              type="email"
              id="Email"
              name="email"
              required
              className="english-textinput input"
            />
            <label htmlFor="Phone">Phone</label>
            <input
              type="number"
              id="Phone"
              name="phone"
              required
              className="english-textinput1 input"
            />
            <label htmlFor="Message">Message</label>
            <input
              type="text"
              id="Message"
              name="message"
              required
              className="english-input1 input"
            />
            <button onClick={handleFormSubmission}
              name="submit"
              type="button"
              className="english-button button"
            >
              Send
            </button>
          </form>
          <div id="contactSubmitted" className="english-container20">
            <span className="english-text158">
              <span>Message sent. </span>
              <br></br>
              <span>
                We will respond within the business day.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>Thank you!</span>
            </span>
          </div>
        </div>
      </div>
      <footer className="english-footer">
        <img
          alt="logo"
          src="/public/yamagatalogo.png"
          className="english-image4"
        />
        <span className="english-text164">
          © 2024 Yamagata Dental P.C., All Rights Reserved.
        </span>
        <div className="english-container21">
          <span className="english-text165">
            <span>Tel: 212-867-1188</span>
            <br></br>
            <span>Fax: 212 953 9110</span>
            <br></br>
          </span>
          <span className="english-text170">
            <span>230 Park Avenue Suite 525</span>
            <br></br>
            <span>New York, NY 10169</span>
          </span>
        </div>
      </footer>
    </div>
  )
}

export default English