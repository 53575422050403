import React, { useState } from "react";
import "./ImageGrid.css"; // Import your CSS file for styling

const ImageGrid = ({ images }) => {
  const [enlargedImage, setEnlargedImage] = useState(null);
  const Enlarge = (image) => {
    setEnlargedImage(image);

  }
  const closeEnlarged = () => {
    setEnlargedImage(null);
  };
  return (
    <div className="image-grid">
      {images.map((image, index) => (
        <div key={index} className="image-item" onClick = {() =>Enlarge(image)}>
          <img src={image.url} alt={`Image ${index + 1}`} />
        </div>
      ))}
      {enlargedImage && (
        <div className="enlarged-image-overlay" onClick={closeEnlarged}>
          <img
            src={enlargedImage.url}
            className= "enlarged-image"
          />
        </div>
      )}
    </div>
  );
};

export default ImageGrid;