import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import ImageSlider from '../components/ImageSlider'
import ImageGrid from '../components/ImageGrid'

import './japanese.css'
const slides = [
  { url: 'https://yamagatadental.net/public/grandcentralinterior.jpg' },
  { url: 'https://yamagatadental.net/public/helmseyinterior.jpg' },
  { url: 'https://yamagatadental.net/public/helmseyoutside.png' },
  { url: 'https://yamagatadental.net/public/20240119_162058ratio.jpg' },
  { url: 'https://yamagatadental.net/public/sign.jpg' },
  { url: 'https://yamagatadental.net/public/Origami.jpg' }
];
const images = [
  { url: 'https://yamagatadental.net/public/staff1ratio.jpg' },
  { url: 'https://yamagatadental.net/public/patient1.jpg' },
  { url: 'https://yamagatadental.net/public/patient2.jpg' },
  { url: 'https://yamagatadental.net/public/patient3.jpg' },
  { url: 'https://yamagatadental.net/public/patient4.jpg' },
  { url: 'https://yamagatadental.net/public/patient5.jpg' },
  { url: 'https://yamagatadental.net/public/patient6.jpg' },
  { url: 'https://yamagatadental.net/public/patient7.jpg' },
  { url: 'https://yamagatadental.net/public/staff3.jpg' },
  { url: 'https://yamagatadental.net/public/staff4.jpg' },

];
const japanese = (props) => {
  return (
    <div className="japanese-container">
      <Helmet>
        <title>japanese - YamagataDental</title>
        <meta property="og:title" content="japanese - YamagataDental" />
      </Helmet>
      <header data-thq="thq-navbar" className="japanese-navbar-interactive">
        <img
          alt="logo"
          src="/public/yamagatalogo.png"
          className="japanese-image"
        />
        <span className="japanese-text">
          <span>Yamagata Dental P.C.</span>
          <br></br>
          <span>山縣歯科医院</span>
        </span>
        <div data-thq="thq-navbar-nav" className="japanese-desktop-menu">
          <nav className="japanese-links">
            <a href="#Home" className="japanese-link">
              Home
            </a>
            <a href="#About" className="japanese-link01">
              About
            </a>
            <a href="#Services" className="japanese-link02">
              Services
            </a>
            {<a href="#Insurance" className="japanese-link02">
              Insurance
            </a>
            }
            <a href="#Location" className="japanese-link03">
              Location
            </a>
            <a href="#Contact" className="japanese-link04">
              Contact
            </a>
            <Link to={{ pathname: '/news', state: true }} className="japanese-link05">
            News
            </Link>
          </nav>
          <div className="japanese-buttons"></div>
        </div>
        <div data-thq="thq-burger-menu" className="japanese-burger-menu" onClick = {ShowMobileMenu}>
          <svg viewBox="0 0 1024 1024" className="japanese-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="japanese-mobile-menu" id="mobileMenu">
          <div className="japanese-nav">
            <div className="japanese-top">
              <img
                alt="image"
                src="/public/yamagatalogo.png"
                className="japanese-logo"
              />
              <div data-thq="thq-close-menu" className="japanese-close-menu" onClick = {HideMobileMenu}>
                <svg viewBox="0 0 1024 1024" className="japanese-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="japanese-links1">
              <a href="#Home" className="japanese-link06" onClick = {HideMobileMenu}>
                Home
              </a>
              <a href="#About" className="japanese-link07" onClick = {HideMobileMenu}>
                About
              </a>
              <a href="#Services" className="japanese-link08" onClick = {HideMobileMenu}>
                Services
              </a>
              {<a href="#Insurance" className="japanese-link08" onClick = {HideMobileMenu}>
                Insurance
              </a>
              }
              <a href="#Location" className="japanese-link09" onClick = {HideMobileMenu}>
                Location
              </a>
              <a href="#Contact" className="japanese-link10" onClick = {HideMobileMenu}>
                Contact
              </a>
              <Link to={{ pathname: '/news', state: true }} className="japanese-link10">
                News
              </Link>
            </nav>
            <div className="japanese-buttons1"></div>
          </div>       
        </div>
        <Link to="/eng" className="japanese-navlink button">
          English
        </Link>
      </header>
      <div id="Home" className="japanese-container01">
      <ImageSlider slides = {slides}/>
      </div>
      <div id="About" className="japanese-container03">
        <h1 className="japanese-text004">患者様に</h1>
        <div className="japanese-container04">
          <span className="japanese-text005">
            <span>
              歯に関することでしたら、どんなことでも山縣歯科医院へご相談下さい。
            </span>
            <br></br>
            <br></br>
            <span>
              当医院では、常駐の日本人歯科医と日本人スタッフが治療とご相談に応じると共に歯周病、インプラントの治療は専門のインハウスドクターが常駐し、治療過程において他の専門医を訪問しなくてはならないという煩わしさはありません。
            </span>
            <br></br>
            <br></br>
            <span>
              個人の事情で治療せず放っておいた歯があるので恥ずかしいと思っている方は、そういう考えは捨てて下さい。
            </span>
            <br></br>
            <br></br>
            <span>
              深刻な歯の問題を抱えていた数多くの患者様が当医院での治療後、健康な生活を取り戻していらっしゃいます。
            </span>
            <br></br>
            <br></br>
            <span>
              麻酔注射の痛みも最大限に軽減し、お子様も泣かずに優しい治療を受けることができます。
            </span>
            <br></br>
            <br></br>
            <span>
              初回治療の後は、未来を明るくするために健康な歯を維持することが楽しみになります。
            </span>
            <br></br>
            <br></br>
            <span>
              問題の歯を治療し患者様の生活の質や仕事の効率を高めることこそが、３０年以上に渡り今日まで山縣歯科医院が心がけてきたことです。
            </span>
            <br></br>
            <br></br>
            <span>歯に関しては、迷わず山縣歯科医院までご連絡下さい。</span>
          </span>
        </div>
        <div className="japanese-container05">
          <div className="japanese-container06">
            <img
              alt="image"
              src="/public/dryamagata-400h.png"
              className="japanese-image1"
            />
            <span className="japanese-text028">Ren Yamagata D.D.S</span>
            <span className="japanese-text029">
              <span>１９８２年​日本歯科大学卒業</span>
              <br></br>
              <span>榎本歯科医院勤務 麴町，東京</span>
              <br></br>
              <span>１９９０年​NYU Dental School卒業</span>
              <br></br>
              <span>歯科医師免許取得</span>
              <br></br>
              <span>１９９１年​山縣歯科医院開業</span>
              <br></br>
              <span>２０２４年​現在に至る</span>
            </span>
          </div>
          <div className="japanese-container07">
            <h1 className="japanese-text041">山縣院長の挨拶</h1>
            <span className="japanese-text042">
              <span>
                日米両国の歯科医師ライセンスを持つ山縣歯科医師は、１９９１年にニューヨークで開業以来３３年間、数え切れないほど多くの日本人の患者様を治療してきました。
              </span>
              <br></br>
              <br></br>
              <span>
                私が治療をした方々は単に患者様というよりは、人生を共に生きていく友人や家族のように感じられます。企業、官公庁からの３年から５年ほどの滞在勤務の間に治療した患者様の帰国はいつも寂しいものですが、帰国後に出張や再派遣で再び治療にみえたり、長期滞在や永住の患者様の幼いお子様方が長きに渡り、最近では親子３代に渡り治療にみえる患者様もいらっしゃいます。
              </span>
              <br></br>
              <br></br>
              <span>
                また、他での一般的な治療ではかなわなかった複雑で難しいケースで当院を訪ね、私の治療後に幸せな日常生活を取り戻された時は、専門医としての達成感も倍増します。
              </span>
              <br></br>
              <br></br>
              <span>
                私は、最高な歯の治療とは、歯科医師自身の患者様一人一人に対しての深い思いに起因する心の産物であるとも考えています。そのため、治療医としての豊かな臨床経験をもとに極めて細やかな診断の上、患者様の長期的福祉をも勘案しながら、基本に基づいた治療方針と熟練した技術を提供してこそ最高な治療となると考え、今日も変わらず診療を行っています。
              </span>
              <br></br>
              <br></br>
              <span>
                過去３０年以上に渡り、現在でも私の治療を信頼して下さっている素晴らしい患者様のためにも山縣歯科医院は更に精進させて頂きます。
              </span>
              <br></br>
              <br></br>
              <span>
                ニューヨーク、その近郊にいらっしゃる皆様の歯は安心して山縣歯科医院にお任せ下さい。
              </span>
              <br></br>
            </span>
          </div>
        </div>
        <div className="japanese-container08">
        <ImageGrid images = {images}/>
        </div>
      </div>
      <div id="Services" className="japanese-container09">
        <h1 className="japanese-text060">
          <span>Dental Services</span>
          <br></br>
        </h1>
        <div className="japanese-container10">
          <div className="japanese-container11">
            <h1 className="japanese-text063">歯科予防処置</h1>
            <span className="japanese-text064">
              <span>定期歯科検診(X-ray)</span>
              <br className="japanese-text066"></br>
              <br></br>
              <span>歯科クリーニング</span>
              <br className="japanese-text069"></br>
              <br></br>
              <span>ナイトガード(歯ぎしり用咬合床)</span>
              <br className="japanese-text072"></br>
              <br></br>
              <span>小児歯科治療</span>
              <br className="japanese-text075"></br>
              <br></br>
              <span>シーラント</span>
            </span>
          </div>
          <div className="japanese-container12">
            <h1 className="japanese-text078">
              <span>補綴(補綴)歯科治療</span>
              <br></br>
            </h1>
            <span className="japanese-text081">
              <span>レジン充填(filling)</span>
              <br className="japanese-text083"></br>
              <br></br>
              <span> クラウン&amp;ブリッジ</span>
              <br className="japanese-text086"></br>
              <br></br>
              <span>インプラント</span>
              <br className="japanese-text089"></br>
              <br></br>
              <span>入れ歯(取り外し可能な義歯)</span>
            </span>
          </div>
          <div className="japanese-container13">
            <h1 className="japanese-text092">
              <span> 保存歯科治療</span>
              <br></br>
            </h1>
            <span className="japanese-text095">
              <span>根管治療</span>
              <br></br>
              <br></br>
            </span>
          </div>
        </div>
        <div className="japanese-container14">
          <div className="japanese-container15">
            <h1 className="japanese-text099">歯周病歯科治療</h1>
            <span className="japanese-text100">
              <span>ディープスケーリング</span>
              <br className="japanese-text102"></br>
              <br></br>
              <span>歯周組織再生療法</span>
              <br className="japanese-text105"></br>
              <br></br>
              <span>歯周外科治療</span>
            </span>
          </div>
          <div className="japanese-container16">
            <h1 className="japanese-text108">
              <span>審美歯科治療</span>
              <br></br>
            </h1>
            <span className="japanese-text111">
              <span>ラミネートベニア</span>
              <br className="japanese-text113"></br>
              <br></br>
              <span>歯のホワイトニング</span>
            </span>
          </div>
          <div className="japanese-container17">
            <h1 className="japanese-text116">歯科矯正治療</h1>
            <span className="japanese-text117">
              <span>小児、成人歯科治療</span>
              <br className="japanese-text119"></br>
              <br></br>
              <span>透明な歯科矯正(インビィザライン)</span>
              <br></br>
            </span>
          </div>
        </div>
        <div className="japanese-container18">
          <div className="japanese-container19">
            <h1 className="japanese-text123">外科治療</h1>
            <span className="japanese-text124">
              <span>抜歯(親知らずも含む)及び一部の外科処置</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      {
      <div id="Insurance" class="japaneseinsurance-container1">
      <h1 class="japaneseinsurance-text1">
        <span>保険について</span>
        <br />
      </h1>     
      <div class="japaneseinsurance-container2">
        <span class="japaneseinsurance-text2">
          <span>各種保険対応　　PPOプランがご利用になれます。</span>
          <br/>
          <br />
          <span>
             
             アメリカの医療保険は日本の公的医療保険制度と異なる事があり、多くは民間保険会社との契約です。
          </span>
          <br/>
          <span>
            歯科保険は1年間に使用できる上限額が決まっており、契約しているプランにより、その金額、保険のカバー内容にも違いがあります。また保険によっては、使用できる医療機関と出来ない医療機関もあります。
          </span>
          <br/>
          <span>
            その為、保険のシステムが分かり難く、受診に戸惑っている方もいらっしゃるのではないでしょうか。
          </span>
          <br/>
          <span>保険についてご質問がありましたら、いつでもご連絡下さい。</span>
          <br/>
          <span>詳しくご説明致します。</span>
        </span>
        <span class="japaneseinsurance-text2">
          <span>
            1. Prestige International Inc.
            提携医院です。こちらの保険に加入している方は、キャッシュレスをご利用になれます。
          </span>
          <br/>
          <br />
          <span>
            2.
            国民健康保険　、社会保険（駐在員の方）、各共済組合保険等の申請書類作成をしております。
          </span>
          <br/>
          <br/>
          <br/>
          <span>
               各企業の指定申請書類などお持ちの方は、ご持参下さいませ。
          </span>
          <br/>
          <span>   材料証明書など手間のかかる書類等、全て作成致します。</span>
          <br/>
          <br/>
          <br/>
          <br/>
        </span>
      </div>
      </div>
    }
      <div id="Location" className="japanese-container20">
        <div className="japanese-container21">
          <div className="japanese-container22">
            <div className="japanese-container23">
              <h1 className="japanese-text127">Location</h1>
              <span className="japanese-text128">
                <span></span>
                <span>Yamagata Dental, P.C.</span>
                <br></br>
                <span>230 Park Avenue, Suite 525</span>
                <br></br>
                <span>New York, NY 10169</span>
                <br></br>
                <br></br>
                <span>グランドセントラル駅北口の通り向かいにあります。</span>
                <br></br>
              </span>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.346294540422!2d-73.97874332345143!3d40.754407635017515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258fdf6c83d7f%3A0xa1a1f948634bb8ed!2sThe%20Helmsley%20Building%2C%20New%20York%2C%20NY%2010017!5e0!3m2!1sen!2sus!4v1705352055826!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
                className="japanese-iframe"
              ></iframe>
              <h1 className="japanese-text139">Business Hours</h1>
              <span className="japanese-text140">
                <span>Monday - Wednesday, Friday and Saturday</span>
                <br></br>
                <span> 10:00am to 6:00pm</span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="Contact" className="japanese-container24">
        <h1 className="japanese-text145">Contact Us</h1>
        <div className="japanese-container25">
          <h1 className="japanese-text146">
            <span>Tel 212-867-1188</span>
            <br></br>
            <span>Fax: 212-953-9110</span>
            <br></br>
          </h1>
          <h1 className="japanese-text151">Email: info@yamagatadental.net</h1>
          <form
            id="contactform"
            className="japanese-form"
          >
            <label htmlFor="Name" className="japanese-label">
              Name
            </label>
            <input
              type="text"
              id="Name"
              name="name"
              required
              className="japanese-input input"
            />
            <label htmlFor="Email" className="japanese-label1">
              Email
            </label>
            <input
              type="email"
              id="Email"
              name="email"
              required
              className="japanese-textinput input"
            />
            <label htmlFor="Phone" className="japanese-label2">
              Phone
            </label>
            <input
              type="number"
              id="Phone"
              name="phone"
              required
              className="japanese-textinput1 input"
            />
            <label htmlFor="Message" className="japanese-label3">
              Message
            </label>
            <input
              type="text"
              id="Message"
              name="message"
              required
              className="japanese-input1 input"
            />
            <button onClick={handleFormSubmission} name="submit" type="button" className="japanese-button button">
              Send
            </button>
          </form>
          <div id="contactSubmitted" className="japanese-container26">
            <span className="japanese-text152">
              <span>Message sent. </span>
              <br></br>
              <span>
                We will respond within the business day.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>Thank you!</span>
            </span>
          </div>
        </div>
      </div>
      <footer className="japanese-footer">
        <img
          alt="logo"
          src="/public/yamagatalogo.png"
          className="japanese-image4"
        />
        <span className="japanese-text159">
          © 2024 YamagataDental P.C., All Rights Reserved.
        </span>
        <div className="japanese-container29">
          <span className="japanese-text160">
            <span>Tel: 212-867-1188</span>
            <br></br>
            <span>Fax: 212-953-9110</span>
            <br></br>
          </span>
          <span className="japanese-text165">
            <span>230 Park Avenue Suite 525</span>
            <br></br>
            <span>New York, NY 10169</span>
          </span>
        </div>
      </footer>
    </div>
  )
}

export default japanese
