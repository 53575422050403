import { useState, useEffect } from "react";
import '../views/english.css'
import "./ImageSlider.css"; // Import your CSS file for styling

const ImageSlider = ({ slides }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [userInterrupt, setUserInterrupt] = useState(false);
    const slideStyles = {
        position: "absolute",
        display: "flex",
        height: "100%",
        backgroundSize: "cover",
        //top: "75px",
        opacity: 1,
        transition: "opacity 1s ease-in-out",
    }
    const goToPrev = () => {
        setUserInterrupt(true);
        const isFirstSlide = currentIndex === 0
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    }
    const goToNext = () => {
        setUserInterrupt(true);
        const isLastSlide = currentIndex === slides.length - 1
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }
    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }
    useEffect(() => {
        const interval = setInterval(() => {
            if (userInterrupt) {
                return;
            }
            nextSlide();

        }, 3000);
        return () => clearInterval(interval);
    }, [currentIndex, userInterrupt]);

    return (       
        <div class= "english-container02">
            {slides.map((slide, index) => (
                <div className="english-container02"
                key={index}
                style={{
                    ...slideStyles,
                    backgroundImage: `url(${slide.url})`,
                    opacity: index === currentIndex ? 1 : 0,                 
                }}
                ></div>
                ))}       
            <div className = "leftArrow" onClick = {goToPrev}>🡸</div>
            <div className = "rightArrow" onClick={goToNext}>🡺</div>
        </div>    
    )
};

export default ImageSlider